<template>
  <el-card>
    <div slot="header">
      <span>角色管理</span>
    </div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
          type="text"
          placeholder="请输入角色名称或者角色代码进行筛选"
          v-model="queryFilter.keyWorlds"
        />
      </el-col>
      <el-button type="primary" @click="falshTable">搜索角色</el-button>
      <el-button
        style="float: right"
        type="success"
        @click="addRoleDialogVisabled = true"
        >新增角色</el-button
      >
    </el-row>

    <el-row style="margin-top: 20px">
      <el-table border stripe :data="tableData">
        <el-table-column label="#" width="80" type="index"></el-table-column>
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column prop="roleCode" label="角色代码"></el-table-column>
        <el-table-column prop="description" label="角色描述"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="updateTime" label="修改时间"></el-table-column>
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="openEditRoleDialog(scope.row)"
              size="mini"
              >编辑</el-button
            >
            <el-button type="danger" @click="deleteRole(scope.row)" size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <!-- 新增角色 -->
    <el-dialog
      :close-on-click-modal="false"
      title="新增角色"
      :visible.sync="addRoleDialogVisabled"
      @close="addRoleDialogClose"
    >
      <el-form
        ref="addRoleFromRef"
        :model="addRoleForm"
        :rules="addRoleRules"
        label-width="100"
      >
        <el-form-item prop="name" label="角色名称">
          <el-input
            v-model="addRoleForm.name"
            autocomplete="off"
            placeholder="角色名称"
          ></el-input>
        </el-form-item>

        <el-form-item prop="description" label="角色描述">
          <el-input
            type="textarea"
            maxlength="300"
            :rows="2"
            show-word-limit
            v-model="addRoleForm.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item prop="permissionIds">
          <label>权限菜单</label
          ><el-button type="text" size="mini" @click="checkAllAddTreeNode"
            >全选</el-button
          ><el-button type="text" size="mini" @click="clearAllAddTreeNode"
            >清除选项</el-button
          >
          <el-tree
            ref="addPermissionTree"
            :check-strictly="true"
            :data="permissionTree"
            :props="defaultProps"
            show-checkbox
            node-key="id"
            class="permission-tree"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="addRoleDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="addRoleDialogConfrim"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑角色 -->
    <el-dialog
      :close-on-click-modal="false"
      title="编辑角色"
      :visible.sync="editRoleDialogVisabled"
    >
      <el-form
        ref="editRoleFromRef"
        :model="editRoleFrom"
        :rules="editRoleFromRules"
        label-width="100"
      >
        <el-form-item prop="name" label="角色名称">
          <el-input
            v-model="editRoleFrom.name"
            autocomplete="off"
            placeholder="角色名称"
          ></el-input>
        </el-form-item>

        <el-form-item prop="description" label="角色描述">
          <el-input
            type="textarea"
            maxlength="300"
            :rows="2"
            show-word-limit
            v-model="editRoleFrom.description"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item prop="permissionIds">
          <label>权限菜单</label
          ><el-button type="text" size="mini" @click="checkAllEditTreeNode"
            >全选</el-button
          ><el-button type="text" size="mini" @click="clearAllEditTreeNode"
            >清除选项</el-button
          >
          <el-tree
            ref="edidtPermissionTree"
            :check-strictly="true"
            :data="permissionTree"
            :props="defaultProps"
            show-checkbox
            node-key="id"
            class="permission-tree"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editRoleDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="editRoleDialogComfrim"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  addRole,
  getAllList,
  getRoleInfoById,
  deleteRole,
  updateRole,
} from '@/api/role.js'

import { getPermissionTress } from '@/api/permission.js'
export default {
  data() {
    return {
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      // 表格数据
      tableData: [],
      permissionTree: [],
      addRoleDialogVisabled: false,
      addRoleForm: {
        name: '',
        description: '',
        permissionIds: [],
      },
      addRoleRules: {
        name: [
          {
            required: true,
            message: '角色名称为必填选项',
            trigger: 'blur',
          },
        ],
        description: null,
      },
      defaultProps: {
        children: 'childrenPermission',
        label: 'name',
      },
      // 编辑角色权限
      editRoleFrom: {
        id: null,
        name: '',
        description: '',
        permissionIds: [],
      },
      editRoleFromRules: {
        name: [
          {
            required: true,
            message: '角色名称为必填选项',
            trigger: 'blur',
          },
        ],
        description: null,
      },
      editRoleDialogVisabled: false,
    }
  },
  created() {
    this.getTableData()
    this.getPermissionTree()
  },
  methods: {
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getTableData()
    },
    falshTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    async getTableData() {
      let { data } = await getAllList(this.queryFilter)

      this.tableData = data.data

      this.queryFilter.total = data.total
    },
    async getPermissionTree() {
      let { data } = await getPermissionTress()
      this.permissionTree = data
    },
    // 新增角色对话框
    addRoleDialogConfrim() {
      this.$refs.addRoleFromRef.validate(async (valid) => {
        if (valid) {
          let perArr = this.$refs.addPermissionTree.getCheckedKeys()

          // if (!perArr || perArr.length <= 0) {
          //   return this.$message.error('当前角色不能分配空权限')
          // }
          this.addRoleForm.permissionIds = perArr

          await addRole(this.addRoleForm)

          this.getTableData()
          this.getPermissionTree()
          this.addRoleDialogVisabled = false
        }
      })
    },
    addRoleDialogClose() {
      this.$refs.addRoleFromRef.resetFields()
    },
    async openEditRoleDialog(row) {
      let { data } = await getRoleInfoById(row.id)

      this.editRoleFrom.id = data.id
      this.editRoleFrom.name = data.roleName
      this.editRoleFrom.description = data.description
      this.editRoleFrom.permissionIds = data.permissionIds
      this.editRoleDialogVisabled = true
      this.$nextTick(() => {
        this.$refs.edidtPermissionTree.setCheckedKeys(
          this.editRoleFrom.permissionIds
        )
      })
    },
    async editRoleDialogComfrim() {
      this.$refs.editRoleFromRef.validate(async (valid) => {
        if (valid) {
          let perArr = this.$refs.edidtPermissionTree.getCheckedKeys()

          if (!perArr || perArr.length <= 0) {
            return this.$message.error('当前角色不能分配空权限')
          }
          this.editRoleFrom.permissionIds = perArr

          await updateRole(this.editRoleFrom)

          this.getTableData()
          this.getPermissionTree()
          this.editRoleDialogVisabled = false
        }
      })
    },
    async deleteRole(row) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteRole(row.id)
          this.getTableData()
          this.getPermissionTree()
          this.$message.success('角色删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getAllId(data) {
      let ids = []
      data.forEach((item) => {
        ids.push(item.id)
        if (item.childrenPermission) {
          let arr = this.getAllId(item.childrenPermission)
          ids = [...ids, ...arr]
        }
      })

      return ids
    },
    checkAllAddTreeNode() {
      let checkIds = this.getAllId(this.permissionTree)
      this.$refs.addPermissionTree.setCheckedKeys(checkIds)
    },
    clearAllAddTreeNode() {
      this.$refs.addPermissionTree.setCheckedKeys([])
    },
    checkAllEditTreeNode() {
      let checkIds = this.getAllId(this.permissionTree)
      this.$refs.edidtPermissionTree.setCheckedKeys(checkIds)
    },
    clearAllEditTreeNode() {
      this.$refs.edidtPermissionTree.setCheckedKeys([])
    },
  },
}
</script>

<style lang="scss" scoped></style>
